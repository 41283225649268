import { useState } from 'react';
import { SelectedVariantIdGroup } from '../../../services/Request.types';
import ImportConfirmationModal from './index';

type Props = {
  selectedVariants: SelectedVariantIdGroup[];
  onImportSuccess: () => void;
};

export default function ImportSelectedProductsBtn({
  selectedVariants,
  onImportSuccess,
}: Props) {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  return (
    <>
      {confirmationModalOpen && (
        <ImportConfirmationModal
          onCancel={() => setConfirmationModalOpen(false)}
          selectedVariants={selectedVariants}
          onSuccess={() => {
            setConfirmationModalOpen(false);
            onImportSuccess();
          }}
        />
      )}
      <div className='flex w-full flex-row items-center justify-end space-x-3'>
        <button
          data-testid='trigger-import-flow-btn'
          disabled={selectedVariants.length === 0}
          type='button'
          className='flex w-full items-center justify-center rounded-lg bg-primary-700 px-3 py-2 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 disabled:bg-primary-600 md:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 disabled:dark:bg-primary-500'
          onClick={() => setConfirmationModalOpen(true)}
        >
          <svg
            className='mr-2 size-3.5'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
          >
            <path
              clipRule='evenodd'
              fillRule='evenodd'
              d='M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z'
            />
          </svg>
          Import selected products
        </button>
      </div>
    </>
  );
}
