import { useDebounce } from '@uidotdev/usehooks';
import { QueryFunctionContext, useSuspenseQuery } from '@tanstack/react-query';
import { startTransition, useCallback, useMemo, useState } from 'react';
import { backendApiInstance } from './api.service';
import useStoreNameContext from '../contexts/StoreNameContext';
import { ProductsGqlRes } from './IResponses.types';

export const useProductsQuery = () => {
  const [afterCursor, setAfterCursor] = useState<string | null>(null);
  const [beforeCursor, setBeforeParam] = useState<string | null>(null);
  const [search, setSearch] = useState<string>('');
  const valueToBeDebounced = useMemo(
    () => ({ search, afterCursor, beforeCursor }),
    [afterCursor, beforeCursor, search],
  );
  const tags = useDebounce(valueToBeDebounced, 1000);

  const result = useSuspenseQuery({
    staleTime: Infinity,
    queryKey: ['products', tags.afterCursor, tags.beforeCursor, tags.search],
    queryFn: async ({
      queryKey: [, afterParam, beforeParam, currSearchParam],
      signal,
    }: QueryFunctionContext<
      [string, string | null, string | null, string]
    >) => {
      const params: Record<string, any> = {
        count: 50,
        search: currSearchParam,
      };

      if (afterParam) {
        params.after = afterParam;
      }

      if (beforeParam) {
        params.before = beforeParam;
      }

      const { data } = await backendApiInstance.get<ProductsGqlRes>(
        'products/development',
        {
          params,
          signal,
        },
      );

      data.products.edges.forEach(productEdge => {
        const originalProductId = productEdge.node.legacyResourceId;
        // eslint-disable-next-line no-param-reassign
        productEdge.node.legacyResourceId = `product_${productEdge.node.legacyResourceId}`;
        productEdge.node.variants.edges.forEach(variantEdge => {
          // eslint-disable-next-line no-param-reassign
          variantEdge.node.legacyResourceId = `variant_${variantEdge.node.legacyResourceId}_product_${originalProductId}_${productEdge.node.title}`;
        });
      });

      return data;
    },
  });

  const hasNextPage = useMemo(
    () => result.data.products.pageInfo.hasNextPage,
    [result.data.products.pageInfo.hasNextPage],
  );
  const hasPreviousPage = useMemo(
    () => Boolean(result.data.products.pageInfo.hasPreviousPage),
    [result.data.products.pageInfo.hasPreviousPage],
  );
  const fetchNextPage = useCallback(() => {
    setBeforeParam(null);
    setAfterCursor(result.data.products.pageInfo.endCursor ?? null);
  }, [result.data.products.pageInfo.endCursor]);
  const fetchPreviousPage = useCallback(() => {
    setAfterCursor(null);
    setBeforeParam(result.data.products.pageInfo.startCursor ?? null);
  }, [result.data.products.pageInfo.startCursor]);

  const memoizedSetSearch = useCallback((newSearch: typeof search) => {
    setSearch(newSearch);
    startTransition(() => {
      setBeforeParam(null);
      setAfterCursor(null);
    });
  }, []);
  return [
    result,
    { hasNextPage, hasPreviousPage, fetchNextPage, fetchPreviousPage },
    [search, memoizedSetSearch],
  ] as const;
};

export const useStoreShopifyTokenQuery = () => {
  const [storeName] = useStoreNameContext();

  return useSuspenseQuery({
    queryKey: ['token', storeName!] as const,
    staleTime: Infinity,
    queryFn: async ({ signal, queryKey: [, currStoreName] }) => {
      const { data } = await backendApiInstance.get<
        { message: string } | 'string'
      >('/users/token', {
        signal,
        params: {
          storename: currStoreName,
        },
      });

      return data;
    },
  });
};

export type IImportedProduct = {
  _id: string;
  storename: string;
  id: string;
  legacyResourceId: string;
  dev_product_id: string;
  dev_product_legacy_id: string;
  title: string;
  handle: string;
  status?: 'InProcess' | 'success' | 'fail';
  error: string[];
  variants: Array<{
    id: string;
    legacyResourceId: string;
    dev_variant_id: string;
    dev_variant_legacy_id: string;
    name: string;
    values: Array<string>;
  }>;
  images: Array<{
    node: {
      id: string;
    };
  }>;
  options: Array<{
    node: {
      id: string;
      alt: string;
      status: string;
    };
  }>;
  createdAt: string;
  updatedAt: string;
  media: Record<string, string> | null;
  __v: number;
};

export const useImportedProductsQuery = () => {
  const [storeName] = useStoreNameContext();

  return useSuspenseQuery({
    queryKey: [
      'productSelections',
      storeName!,
      'productSuccessSelection',
    ] as const,
    refetchInterval: 2_000,
    staleTime: 2_000,
    notifyOnChangeProps: ['data', 'error'],
    queryFn: async ({ queryKey: [, currStoreName], signal }) => {
      const { data } = await backendApiInstance.get<Array<IImportedProduct>>(
        '/products',
        {
          signal,
          params: {
            storename: currStoreName,
          },
        },
      );

      return data;
    },
  });
};

export const useProductsSelectionApi = (
  filterProducts?: (product: IImportedProduct) => boolean,
) => {
  const { data } = useImportedProductsQuery();
  return useMemo(
    () => ({
      data: data
        .filter(filterProducts || Boolean)
        .map(product =>
          product.variants
            .filter(Boolean)
            .filter(() => !product.status || product.status === 'success')
            .map(
              variant =>
                `variant_${variant.dev_variant_legacy_id as `${number}`}_product_${product.dev_product_legacy_id as `${number}`}_${product.title}` as const,
            ),
        )
        .flat(),
    }),
    [data, filterProducts],
  );
};

export const useUnImportedProductsSelectionApi = () => {
  const { data } = useImportedProductsQuery();
  return useMemo(
    () => ({
      data: data.filter(p => Boolean(p.status) && p.status !== 'success'),
    }),
    [data],
  );
};

export type PendingImagesRes = Array<{
  storename: string;
  dev_product_id: string;
  dev_product_legacy_id: string;
  title: string;
  variants: Array<
    Array<{
      id: string;
      title: string;
      variant_legacy_id: string;
      media: any;
      dev_variant_id: string;
      dev_variant_legacy_id: string;
    }>
  >;
  img_src: Array<string>;
  createdAt: string;
  updatedAt: string;
}>;

export const usePendingImagesQuery = () => {
  const [storename] = useStoreNameContext();

  return useSuspenseQuery({
    queryKey: ['pendingImages', storename],
    queryFn: async ({ signal }) => {
      const res = await backendApiInstance.get<PendingImagesRes>(
        '/products/appendimage',
        {
          signal,
          params: {
            storename,
          },
        },
      );
      return res.data;
    },
  });
};
